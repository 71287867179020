<template>
  <main class="layout-hasColG">
    <header class="layer-primary">
      <div class="main-img main-img-random">
        <img loading="lazy" src="https://cdn.maine-et-loire.fr/ecosysteme_ressources/img/random_une.png" alt="" width="1800" height="650" />
      </div>
    </header>

    <section class="section-content" id="section-content" tabindex="-1">
      <header>
        <nav aria-label="Breadcrumb">
          <ul id="site-nav-breadcrumbs" class="site-nav-breadcrumbs">
            <li><a href="/">Accueil</a></li>
            <li>Aide à la navigation</li>
          </ul>
        </nav>

        <h1>Aide à la navigation</h1>

        <p class="intro" id="introPage">Cette page vise à vous guider dans l'utilisation du site assmat.maine-et-loire.fr</p>
        <div class="toolbox">
          <p id="tempsLecture">
            <span class="fa fa-solid fa-book-reader"></span>

            Temps de lecture&nbsp;:

            <span id="dureeLecture">3</span>&nbsp;min.
          </p>
        </div>
      </header>

      <div>
        <aside class="sidebar-secondary">
          <section class="contact">
            <div class="ce-textpic ce-center ce-above">
              <div class="ce-bodytext">
                <p>
                  <strong>Département de Maine-et-Loire</strong><br />
                  CS 94104<br />
                  Angers Cedex 9
                </p>
                <p><b>Tél. :</b><a href="Tel:+33241814949" title="Appeler le 02 41 81 49 49">02 41 81 49 49</a></p>
                <p><a href="https://www.maine-et-loire.fr/contacter-le-departement" class="btn-cta-standard" target="_blank" title="Contactez-nous - Nouvelle fenêtre">Contactez-nous</a></p>
              </div>
            </div>
          </section>
        </aside>
        <article id="main-content">
          <h2>Navigation</h2>
          <div class="ce-textpic ce-center ce-above">
            <div class="ce-bodytext">
              <h3>Navigation principale</h3>
              <p>Le site est structuré en trois rubriques, chacune accessible depuis le menu de navigation principal :</p>
              <ul>
                <li>Parent ;</li>
                <li>Assistant maternel ;</li>
                <li>Collectivité.</li>
              </ul>
              <h3>Autres systèmes de navigation</h3>
              <ul>
                <li>un moteur de recherche présent sur la page d'accueil pour rechercher un.e assistant.e maternel.le ;</li>
                <li>un plan du site accessible via un lien situé en pied de page ;</li>
                <li>un fil d’Ariane au-dessus du titre de la page ;</li>
                <li>le logo permet de revenir à la page d'accueil ;</li>
                <li>
                  en pied de page des sections complémentaires sont proposées :
                  <ul>
                    <li>Accessibilité ;</li>
                    <li>Mentions légales ;</li>
                    <li>Données personnelles ;</li>
                    <li>Plan du site.</li>
                  </ul>
                </li>
              </ul>
              <h3>Navigation par titres</h3>
              <p>
                Le titre de niveau 1 correspond au titre de la page consultée, sauf pour la page d’accueil où il correspond au titre du site.<br /><br />
                La plupart des technologies d’assistance et des navigateurs facilitent la navigation par les titres. La plupart des lecteurs d’écran fournissent une fonctionnalité pour sauter au titre suivant par exemple :
              </p>
              <ul>
                <li>la touche "H" mène au titre suivant ;</li>
                <li>la touche "1" mène au prochain titre de niveau 1 ;</li>
                <li>la touche "2" mène au prochain titre de niveau 2 ;</li>
                <li>la combinaison des touches "Insert" + "F6" fournit une liste des titres, qui peut être triée par ordre de tabulation ou par ordre alphabétique.</li>
              </ul>
            </div>
          </div>
          <h3>Navigation au clavier</h3>
          <div class="ce-textpic ce-center ce-above">
            <div class="ce-bodytext">
              <p>
                La touche de tabulation permet d'accéder successivement à chaque lien du site.<br /><br /><strong>Pour les composants interactifs :</strong> le site embarque des composants basés sur la technologie JavaScript. Leur structure et leur
                comportement clavier respectent les recommandations internationales.<br /><br />
                Pour que ces composants fonctionnent de manière prédictible et selon les notices d'utilisation que nous vous fournissons ci-dessous, les utilisateurs de lecteurs d'écrans doivent les atteindre en mode formulaire, ou activer le mode
                formulaire une fois dessus.
              </p>
              <h3>Retour en haut de page</h3>
              <p>En bas de l'écran se trouve un lien de retour "Haut de page" vous permettant de retrouver rapidement le menu principal et le chemin de navigation.</p>
              <h3>Liens d’accès rapides</h3>
              <p>Les liens d’accès rapide, présents au début du document, permettent une navigation facilitée vers les régions principales des pages.</p>
            </div>
          </div>

          <h2>Fonctionnalités d’affichage</h2>
          <div class="ce-textpic ce-center ce-above">
            <div class="ce-bodytext">
              <h3>Taille des textes</h3>
              <p>La taille de tous les textes repose sur l'utilisation d'unités relatives, ce qui permet de la modifier dans la plupart des navigateurs. Cette opération peut être réalisée par l'utilisation des commandes suivantes :</p>
              <ul>
                <li>Touche "CTRL" et "molette de la souris", ou</li>
                <li>Touches "CTRL" et "+" (agrandir la taille du texte) ou "-" (diminuer la taille du texte), ou</li>
                <li>Menus du navigateur : Affichage &gt; Taille du texte.</li>
              </ul>
              <p><strong>Pour modifier la taille du site</strong></p>
              <p>
                L'affichage du site s'adapte du format "téléphone" au format "1600 x 1200 pixels".<br />
                Les colonnes qui sont placées à droite de l'écran pour une résolution égale ou supérieure à 768 pixels de large sont linéarisées sous le contenu pour une résolution inférieure à 768 pixels et à la synthèse vocale.
              </p>
              <p>Si vous naviguez sur PC, utilisez la combinaison de touches "Ctrl" + "+" (la touche "plus") pour agrandir la taille des caractères.</p>
              <p>
                La combinaison de touches "Ctrl" + "-" (la touche "moins") permet de réduire la taille des caractères.<br />
                Des appuis successifs sur ces touches vous permettent d’augmenter ou de diminuer la taille des caractères par paliers.
              </p>
              <p>La combinaison de touches "Ctrl" + "0" (le chiffre "zéro") vous permet de revenir à la taille du texte par défaut.</p>
              <p>Si vous naviguez sur Mac, remplacez, dans les combinaisons annoncées pour l'utilisation d'un PC, la touche "Ctrl" par la touche "Cmd".</p>
            </div>
          </div>
          <h3>Fonctionnalité "Dyslexie"</h3>
          <div class="ce-textpic ce-center ce-above">
            <div class="ce-bodytext">
              <p>
                Placé en haut de page dans le menu accessibilité, le bouton "Dyslexie" permet de modifier la police de caractère afin de proposer une police adaptée (empattement plus important à la base des caractères) aux personnes souffrant de
                dyslexie.
              </p>
            </div>
          </div>
          <h3>Fonctionnalité "Contraste"</h3>
          <div class="ce-textpic ce-center ce-above">
            <div class="ce-bodytext">
              <p>Placé en haut de page dans le menu accessibilité, le bouton "Contraste" permet de basculer le site en version noir et blanc pour un contraste maximum entre la couleur de fond et la couleur du texte.</p>
            </div>
          </div>

          <h2>Plugins nécessaires à la consultation du site</h2>
          <div class="ce-textpic ce-center ce-above">
            <div class="ce-bodytext">
              <p>Dans certaines pages se trouvent des <abbr title="Portable Document Format">PDF</abbr> à télécharger. Afin de lire ces documents, vous devez disposer du logiciel Adobe Acrobat Reader, téléchargeable sur le site d'Adobe.</p>
            </div>
          </div>

          <h2>Signaler un dysfonctionnement</h2>
          <div class="ce-textpic ce-center ce-above">
            <div class="ce-bodytext">
              <p>
                Si, malgré notre vigilance et étant donnée la multiplicité des pages, certains contenus peuvent s'avérer non conformes avec un ou plusieurs critères du RGAA., n’hésitez pas à
                <a href="mailto:numerique@maine-et-loire.fr" title="Contacter notre service chargé de l'accessibilité - Fenêtre de messagerie">contacter notre service chargé de l'accessibilité</a> : numerique@maine-et-loire.fr.
              </p>
            </div>
          </div>

          <h2>Défenseur des droits</h2>
          <div class="ce-textpic ce-center ce-above">
            <div class="ce-bodytext">
              <p>
                Si vous constatez un défaut d'accessibilité vous empêchant d'accéder à un contenu ou une fonctionnalité du site, que vous nous le signalez et que vous ne parvenez pas à obtenir une réponse rapide de notre part, vous êtes en droit de
                faire parvenir vos doléances ou une demande de saisine au Défenseur des droits.<br /><br />
                Plusieurs moyens sont à votre disposition :
              </p>
              <ul>
                <li>
                  un
                  <a href="http://www.defenseurdesdroits.fr/contact" title="Formulaire de contact du Défenseur des droits - Nouvelle fenêtre" target="_blank" class="external-link-new-window" data-htmlarea-external="1" rel="noopener noreferrer"
                    >formulaire de contact</a
                  >
                  ;
                </li>
                <li>
                  la
                  <a href="http://www.defenseurdesdroits.fr/fr/office/" title="liste du ou des délégués de votre région - Nouvelle fenêtre" target="_blank" class="external-link-new-window" data-htmlarea-external="1" rel="noopener noreferrer"
                    >liste du ou des délégués de votre région</a
                  >
                  avec leurs informations de contact directs ;
                </li>
                <li>une adresse postale : Le Défenseur des droits - 7 rue Saint-Florentin - 75409 Paris Cedex 08</li>
              </ul>
            </div>
          </div>
        </article>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: 'aide-nav'
};
</script>
